import React from 'react'
import G1 from '../Assets/Og/2players.397e8a67a853e9c954a6180e8e6bbb3b.svg'
import G2 from '../Assets/Og/action.1d4ff83d94555a807037f5d8d566e5d2.svg'
import G3 from '../Assets/Og/adventure.65eca61890726efc4001059f5b6a2ded.svg'
import G4 from '../Assets/Og/basketball.4499f22d7a5189764e5828ddf16f0881.svg'
import G5 from '../Assets/Og/beauty.c98133a65d6a63276d6b0974ed43aaaa.svg'
import G6 from '../Assets/Og/car.63772aa5a667366a7da2b3eacebabd56.svg'
import G7 from '../Assets/Og/casual.e7249abd3323f3f3639f15b490013167.svg'
import G8 from '../Assets/Og/clicker.946190dc7ef8d38f6c94a7840a7f6327.svg'
import G9 from '../Assets/Og/controller.59fadac22b5e4c8ded75dcad14f886b4.svg'
import G10 from '../Assets/Og/minecraft.b65f96cce32ee60800a0bc2220db99d1.svg'
import G11 from '../Assets/Og/stickman.d118b2e3ad5e520487d96396d83fd237.svg'
import G12 from '../Assets/Og/shooting.e79847a542116e7c15bef4a1eac51f42.svg'
import G13 from '../Assets/Og/towerdefense.a8c738b2e3b71a080d71008a4d8fc0f2.svg'
import G14 from '../Assets/Og/puzzle.93ca52c0aed9004e97801189337451ee.svg'
import G15 from '../Assets/Og/multiplayer.ca5333571e224b425298ce235fb6f67d.svg'
import G16 from '../Assets/Og/flash.fc0cd4512fb124c8356dfd5d76761075.svg'
import G17 from '../Assets/Og/horror.1d2573c0d858d1465a2eff1baf06be79.svg'
import G18 from '../Assets/Og/soccer.d4c356a3f3ac08e8fc52b561fc687bb6.svg'
import G19 from '../Assets/Og/sports.ac54c74e315a284b2cc5f74427acb7b2.svg'
import G20 from '../Assets/Og/morelogos.ab441d768d2bee0ef8b4.png'
import { Link } from 'react-router-dom'

function Slider() {
    return (
        <div class="IB-Main">
            <div class="IB-Container">
                <div class="IB-Section">
                    <Link to="/hat-hands">
                        <div class="IB-Row-1">
                            <div class="IB-Column-1">
                                <img src={G1} alt="2Players Game" />
                            </div>
                            <div class="IB-Column-2">2Players</div>
                        </div>
                    </Link>

                    <Link to="/night-ninja">
                        <div class="IB-Row-2">
                            <div class="IB-Column-1">
                                <img src={G2} alt="Action Game" />
                            </div>
                            <div class="IB-Column-2">Action</div>
                        </div>
                    </Link>

                    <Link to="/the-viking">
                        <div class="IB-Row-3">
                            <div class="IB-Column-1">
                                <img src={G3} alt="Adventure Game" />
                            </div>
                            <div class="IB-Column-2">Adventure</div>
                        </div>
                    </Link>

                    <Link to="/super-bouncy-egg">
                        <div class="IB-Row-4">
                            <div class="IB-Column-1">
                                <img src={G4} alt="Basketball Game" />
                            </div>
                            <div class="IB-Column-2">Basketball</div>
                        </div>
                    </Link>

                    <Link to="/color-shot">
                        <div class="IB-Row-5">
                            <div class="IB-Column-1">
                                <img src={G5} alt="Beauty Game" />
                            </div>
                            <div class="IB-Column-2">Beauty</div>
                        </div>
                    </Link>

                    <Link to="/drift-bus">
                        <div class="IB-Row-6">
                            <div class="IB-Column-1">
                                <img src={G6} alt="Car Game" />
                            </div>
                            <div class="IB-Column-2">Car</div>
                        </div>
                    </Link>

                    <Link to="/find-diffrence">
                        <div class="IB-Row-7">
                            <div class="IB-Column-1">
                                <img src={G7} alt="Casual Game" />
                            </div>
                            <div class="IB-Column-2">Casual</div>
                        </div>
                    </Link>

                    <Link to="/bitcoin-millionaire">
                        <div class="IB-Row-8">
                            <div class="IB-Column-1">
                                <img src={G8} alt="Clicker Game" />
                            </div>
                            <div class="IB-Column-2">Clicker</div>
                        </div>
                    </Link>

                    <Link to="/two-cat-cute">
                        <div class="IB-Row-9">
                            <div class="IB-Column-1">
                                <img src={G9} alt="Controller Game" />
                            </div>
                            <div class="IB-Column-2">Controller</div>
                        </div>
                    </Link>

                    <Link to="/panda-bloock">
                        <div class="IB-Row-10">
                            <div class="IB-Column-1">
                                <img src={G10} alt="Minecraft Game" />
                            </div>
                            <div class="IB-Column-2">Minecraft</div>
                        </div>
                    </Link>

                    <Link to="/pirates">
                        <div class="IB-Row-11">
                            <div class="IB-Column-1">
                                <img src={G11} alt="Stickman Game" />
                            </div>
                            <div class="IB-Column-2">Stickman</div>
                        </div>
                    </Link>

                    <Link to="/preposition-quest">
                        <div class="IB-Row-12">
                            <div class="IB-Column-1">
                                <img src={G12} alt="Shooting Game" />
                            </div>
                            <div class="IB-Column-2">Shooting</div>
                        </div>
                    </Link>

                    <Link to="/block-tower">
                        <div class="IB-Row-13">
                            <div class="IB-Column-1">
                                <img src={G13} alt="TowerDefense Game" />
                            </div>
                            <div class="IB-Column-2">TowerDefense</div>
                        </div>
                    </Link>

                    <Link to="/baggeage-reclaim">
                        <div class="IB-Row-14">
                            <div class="IB-Column-1">
                                <img src={G14} alt="Puzzle Game" />
                            </div>
                            <div class="IB-Column-2">Puzzle</div>
                        </div>
                    </Link>

                    <Link to="/alien-connect">
                        <div class="IB-Row-15">
                            <div class="IB-Column-1">
                                <img src={G15} alt="Multiplayer Game" />
                            </div>
                            <div class="IB-Column-2">Multiplayer</div>
                        </div>
                    </Link>

                    <Link class="Res-Last-5" to="/play">
                        <div class="IB-Row-16">
                            <div class="IB-Column-1">
                                <img src={G16} alt="More Game" />
                            </div>
                            <div class="IB-Column-2">Flash</div>
                        </div>
                    </Link>

                    <Link class="Res-Last-4" to="/disc-destroy">
                        <div class="IB-Row-17">
                            <div class="IB-Column-1">
                                <img src={G17} alt="Flash Game" />
                            </div>
                            <div class="IB-Column-2">Horror</div>
                        </div>
                    </Link>

                    <Link class="Res-Last-3" to="/rleangue">
                        <div class="IB-Row-18">
                            <div class="IB-Column-1">
                                <img src={G18} alt="Horror Game" />
                            </div>
                            <div class="IB-Column-2">Soccer</div>
                        </div>
                    </Link>

                    <Link class="Res-Last-2" to="/valentine-hidden">
                        <div class="IB-Row-19">
                            <div class="IB-Column-1">
                                <img src={G19} alt="Soccer Game" />
                            </div>
                            <div class="IB-Column-2">Sports</div>
                        </div>
                    </Link>

                    <Link class="Res-Last-1" to="/bridge-runner">
                        <div class="IB-Row-20">
                            <div class="IB-Column-1">
                                <img src={G20} alt="Sports Game" />
                            </div>
                            <div class="IB-Column-2">More Games</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Slider