import React from 'react'
import { Link } from 'react-router-dom'

import Game1 from '../Assets/GameAssets/AlienConnect.jpg';
import Game2 from '../Assets/GameAssets/BaggeageReclaim.jpg';
import Game3 from '../Assets/GameAssets/BallTube.jpg';
import Game4 from '../Assets/GameAssets/BitCoinMillionaire.jpg';
import Game5 from '../Assets/GameAssets/BlockTower.jpg';
import Game6 from '../Assets/GameAssets/BrickBreaker.jpg';
import Game7 from '../Assets/GameAssets/BridgeRunner.jpg';
import Game8 from '../Assets/GameAssets/CatMatch.jpg';
import Game9 from '../Assets/GameAssets/ColorHit.jpg';
import Game10 from '../Assets/GameAssets/ColorShot.jpg';
import Game11 from '../Assets/GameAssets/DinoClicker.jpg';
import Game12 from '../Assets/GameAssets/DiscDestroy.jpg';
import Game13 from '../Assets/GameAssets/DriftBus.jpg';
import Game14 from '../Assets/GameAssets/FindThe3Diffrence.jpg';
import Game15 from '../Assets/GameAssets/FlyingJet.jpg';
import Game16 from '../Assets/GameAssets/GrassCutting.jpg';
import Game17 from '../Assets/GameAssets/HatHands.jpg';
import Game18 from '../Assets/GameAssets/NightNinja.jpg';
import Game19 from '../Assets/GameAssets/PandaBloock.jpg';
import Game20 from '../Assets/GameAssets/Pirates.jpg';
import Game21 from '../Assets/GameAssets/PrepositionQuest.jpg';
import Game22 from '../Assets/GameAssets/RLeangue.jpg';
import Game23 from '../Assets/GameAssets/RopeChallenge.jpg';
import Game24 from '../Assets/GameAssets/Snowland.jpg';
import Game25 from '../Assets/GameAssets/SummerMaze.jpg';
import Game26 from '../Assets/GameAssets/SuperBouncyEgg.jpg';
import Game27 from '../Assets/GameAssets/TheViking.jpg';
import Game28 from '../Assets/GameAssets/TowerOfFall.jpg';
import Game29 from '../Assets/GameAssets/TwoCatCute.jpg';
import Game30 from '../Assets/GameAssets/ValentineHidden.jpg';

function GameBox() {
    return (
        <>
            <div className="GameBox">
                <div className="GB-Main">
                    <div className="GB-Grid">
                        <Link to="/alien-connect">
                            <div className="GB-GamePart GB-GamePartNew1">
                                <img src={Game1} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Alien Connect</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/baggeage-reclaim">
                            <div className="GB-GamePart GB-GamePartHot1">
                                <img src={Game2} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Baggeage Reclaim</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/ball-tube">
                            <div className="GB-GamePart GB-GamePartNew2">
                                <img src={Game3} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Ball Tube</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/bitcoin-millionaire">
                            <div className="GB-GamePart GB-GamePartHot2">
                                <img src={Game4} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>BitCoin Millionaire</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/block-tower">
                            <div className="GB-GamePart">
                                <img src={Game5} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Block Tower</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/brick-breaker">
                            <div className="GB-GamePart">
                                <img src={Game6} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Brick Breaker</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/bridge-runner">
                            <div className="GB-GamePart">
                                <img src={Game7} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Bridge Runner</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/cat-match">
                            <div className="GB-GamePart">
                                <img src={Game8} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Cat Match</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/color-hit">
                            <div className="GB-GamePart">
                                <img src={Game9} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Color Hit</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/color-shot">
                            <div className="GB-GamePart">
                                <img src={Game10} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Color Shot</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/dino-clicker">
                            <div className="GB-GamePart">
                                <img src={Game11} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Dino Clicker</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/disc-destroy">
                            <div className="GB-GamePart">
                                <img src={Game12} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Disc Destroy</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/drift-bus">
                            <div className="GB-GamePart">
                                <img src={Game13} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Drift Bus</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/find-diffrence">
                            <div className="GB-GamePart">
                                <img src={Game14} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Find Diffrence</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/flying-jet">
                            <div className="GB-GamePart">
                                <img src={Game15} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Flying Jet</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/grass-cutting">
                            <div className="GB-GamePart">
                                <img src={Game16} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Grass Cutting</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/hat-hands">
                            <div className="GB-GamePart">
                                <img src={Game17} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Hat Hands</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/night-ninja">
                            <div className="GB-GamePart">
                                <img src={Game18} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Night Ninja</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/panda-bloock">
                            <div className="GB-GamePart">
                                <img src={Game19} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Panda Bloock</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/pirates">
                            <div className="GB-GamePart">
                                <img src={Game20} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Pirates</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/preposition-quest">
                            <div className="GB-GamePart">
                                <img src={Game21} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Preposition Quest</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/rleangue">
                            <div className="GB-GamePart">
                                <img src={Game22} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>RLeangue</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/rope-challenge">
                            <div className="GB-GamePart">
                                <img src={Game23} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Rope Challenge</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/snowland">
                            <div className="GB-GamePart">
                                <img src={Game24} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Snowland</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/summer-maze">
                            <div className="GB-GamePart">
                                <img src={Game25} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Summer Maze</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/super-bouncy-egg">
                            <div className="GB-GamePart">
                                <img src={Game26} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Super Bouncy Egg</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/the-viking">
                            <div className="GB-GamePart">
                                <img src={Game27} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>The Viking</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/tower-if-fall">
                            <div className="GB-GamePart">
                                <img src={Game28} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Tower Of Fall</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/two-cat-cute" className='Res_Dn'>
                            <div className="GB-GamePart">
                                <img src={Game29} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Two Cat Cute</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/valentine-hidden" className='Res_Dn'>
                            <div className="GB-GamePart">
                                <img src={Game30} alt="Game Assets" />
                                <div className="GB-Name">
                                    <p>Valentine Hidden</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GameBox