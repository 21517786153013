import React from 'react'
import GameBox from '../Components/GameBox'

function Play() {
  return (
    <>
    <span style={{marginTop: "15px", display: "block"}}></span>
     <GameBox />
    </>
  )
}

export default Play