import React from 'react'

function Terms() {
  return (
    <>
      <div class="wrapper">
        <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Terms and Conditions</h1>

        <p>Welcome to Justify Games! These Terms and Conditions govern your use of Justify Games, its web sites, applications, and offerings. By gaining access to or using the Game, you compromise to comply with these Terms. If you do not agree, please chorus from the usage of the Game.</p>

        <h2>1. Account Registration</h2>

        <p>1.1. Account Creation: Some Game features may require account registration. You have to provide accurate facts all through registration and keep your info up to date.</p>

        <p>1.2. Account Security: You are liable for keeping the confidentiality of your account credentials. Notify us immediately of any unauthorized use or safety breach.</p>

        <h2>2. Use of the Game</h2>

        <p>2.1. Lawful Use: Use the Game simplest for lawful functions and in compliance with these Terms. Do not use it in a manner that damages, disables, or overburdens the Game.</p>

        <p>2.2. Prohibited Activities: Do no longer interfere with the Game's operation or disrupt functionality through dangerous code or conduct.</p>

        <h2>3. Intellectual Property</h2>

        <p>3.1. Ownership: The Game and its content (textual content, graphics, trademarks, and so on.) are owned or certified through us and protected by using intellectual property legal guidelines.</p>

        <p>3.2. Usage Restrictions: You are granted a restrained, non-special license for non-public, non-commercial use. Without our consent, do no longer replica, modify, or create by-product works from the Game or its content material.</p>

        <h2>4. User Content</h2>

        <p>4.1. Submission: The Game may will let you publish User Content (text, images, motion pictures). By submitting, you provide us a license to use the content material in connection with the Game.</p>

        <p>4.2. Ownership: Ensure you have rights to the User Content and that it complies with 1/3-celebration rights and laws.</p>

        <h2>5. Limitation of Liability</h2>

        <p>5.1. Exclusion: To the fullest quantity authorised by means of regulation, we are not accountable for damages arising out of your use of the Game or these Terms.</p>

        <p>5.2. Maximum Liability: Our general legal responsibility for any declare associated with these Terms or Game use does now not exceed the quantity paid, if any, with the aid of you for Game get admission to.</p>

        <h2>6. Termination</h2>

        <p>6.1. Right to Terminate: We may also droop or terminate your Game get admission to without observe or legal responsibility. Upon termination, end all Game use.</p>

        <h2>7. Governing Law</h2>

        <p>7.1. Jurisdiction: These Terms are ruled through [Your Jurisdiction]'s laws, aside from war of law provisions.</p>

        <h2>8. Changes to Terms</h2>

        <p>8.1. Updates: We can also alter these Terms without note. Revised Terms are effective upon posting. Continued Game use constitutes popularity of changes.</p>

        <h2>9. Contact Us</h2>

        <p>9.1. Support: For questions or concerns about the Game or these Terms, touch us at Justify Gamesonline@gmail.Com.</p>
      </div>
    </>
  )
}

export default Terms