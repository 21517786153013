import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

import './Stylesheets/Style.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="Wrapper">
      <div className="Container">
        <Router>
          <App />
        </Router>
      </div>
    </div>
  </React.StrictMode>
);
