import React from 'react'
import { Link } from 'react-router-dom'

import Game1 from '../Assets/GameAssets/AlienConnect.jpg';
import Game2 from '../Assets/GameAssets/BaggeageReclaim.jpg';
import Game3 from '../Assets/GameAssets/BallTube.jpg';
import Game4 from '../Assets/GameAssets/BitCoinMillionaire.jpg';
import Game5 from '../Assets/GameAssets/BlockTower.jpg';
import Game6 from '../Assets/GameAssets/BrickBreaker.jpg';
import Game7 from '../Assets/GameAssets/BridgeRunner.jpg';
import Game8 from '../Assets/GameAssets/CatMatch.jpg';
import Game9 from '../Assets/GameAssets/ColorHit.jpg';
import Game10 from '../Assets/GameAssets/ColorShot.jpg';
import Game11 from '../Assets/GameAssets/DinoClicker.jpg';
import Game12 from '../Assets/GameAssets/DiscDestroy.jpg';
import Game13 from '../Assets/GameAssets/DriftBus.jpg';
import Game14 from '../Assets/GameAssets/FindThe3Diffrence.jpg';
import Game15 from '../Assets/GameAssets/FlyingJet.jpg';
import Game16 from '../Assets/GameAssets/GrassCutting.jpg';
import Game17 from '../Assets/GameAssets/HatHands.jpg';
import Game18 from '../Assets/GameAssets/NightNinja.jpg';
import Game19 from '../Assets/GameAssets/PandaBloock.jpg';
import Game20 from '../Assets/GameAssets/Pirates.jpg';
import Game21 from '../Assets/GameAssets/PrepositionQuest.jpg';
import Game22 from '../Assets/GameAssets/RLeangue.jpg';
import Game23 from '../Assets/GameAssets/RopeChallenge.jpg';
import Game24 from '../Assets/GameAssets/Snowland.jpg';
import Game25 from '../Assets/GameAssets/SummerMaze.jpg';
import Game26 from '../Assets/GameAssets/SuperBouncyEgg.jpg';
import Game27 from '../Assets/GameAssets/TheViking.jpg';
import Game28 from '../Assets/GameAssets/TowerOfFall.jpg';
import Game29 from '../Assets/GameAssets/TwoCatCute.jpg';
import Game30 from '../Assets/GameAssets/ValentineHidden.jpg';

function Blog() {
  return (
    <>
      <div className="wrapper">
        <div className="Main_Blog">
          <div className="Sec_Blog">
            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game1} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Alien Connect Game</h1>
                  <p>Alien Connect is a fast-paced matching game where you connect monsters to score points. With vibrant graphics and a race against time, it’s an addictive challenge. Strategically connect monsters of the same type, earn high scores, and collect time power-ups for extra game time. Can you become the Alien Connect champion?</p>

                  <Link className='Play_Btn' to="/alien-connect">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game2} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Baggeage Reclaim Game</h1>
                  <p>Starting as a Trainee, your goal is to guide the red luggage to the exit.

                    Please note that our product is exclusively sold through Codecanyon and not available elsewhere.</p>

                  <Link className='Play_Btn' to="/baggeage-reclaim">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game3} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Ball Tube Game</h1>
                  <p>“Ball Tube” is a hyper-casual game where you have to throw balls into the basket with a cannon! Calculate the trajectory of the ball, aim, wait for the moment and shoot!</p>

                  <Link className='Play_Btn' to="/ball-tube">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game4} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>BitCoin Millionaire Game</h1>
                  <p>BITCOIN MILLIONAIREEnjoy the Bitcoin world in this clicker idle game. Immerse yourself in the crypto world simulation, mine your bitcoins, and use the rewards to buy new upgrades and features</p>

                  <Link className='Play_Btn' to="/bitcoin-millionaire">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game5} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Block Tower Game</h1>
                  <p>Block Tower is an exciting game where you carefully place blocks to build a towering structure. The goal is to stack all the blocks without letting any blocks or the tower itself fall. Precision and strategy are key to reaching new heights!</p>

                  <Link className='Play_Btn' to="/block-tower">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game6} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Brick Breaker Game</h1>
                  <p>Welcome to “Brick Breaker Retro”! Brick Breaker Retro is a classic brick game popular all over the world.You need to break many bricks. A simple classic/retro game with many bricks. </p>

                  <Link className='Play_Btn' to="/brick-breaker">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game7} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Bridge Runner Game</h1>
                  <p>“Bridge Runner combines speed, precision and creativity. You will feel the adrenaline rush when running on the bridge that you built yourself. But remember and be careful of any existing trap obstacles that can make you fall into a deep ravine or die from traps.</p>

                  <Link className='Play_Btn' to="/bridge-runner">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game8} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Cat Match Game</h1>
                  <p>Happy Cat Adventure is a platformer game, cat already to journey beautiful world, collect melon and get score, defeat enemies and collect 7 star to unlock the house door.</p>

                  <Link className='Play_Btn' to="/cat-match">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game9} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Color Hit Game</h1>
                  <p>Throw the dart! color hit is an attractive Casual game with simple gameplay. The gameplay is simple and understandable for the player. You can create more stages for the game or customize it as you wish, for this please read the documentations.</p>

                  <Link className='Play_Btn' to="/color-hit">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game10} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Color Shot Game</h1>
                  <p>Shoot the ball until it reaches the blue circle in the center to get the highest score, avoid the ball touching one of the colors orbiting around it to avoid losing a life as they will spin faster and faster to make it harder to get a higher score.</p>

                  <Link className='Play_Btn' to="/color-shot">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game11} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Dino Clicker Game</h1>
                  <p>Dino Click is an exciting game where you have to develop your own dinosaur park! Start with the smallest dinosaur that has just hatched from its shell. By clicking on it, you collect resources that can be used to get new dinosaurs.</p>

                  <Link className='Play_Btn' to="/dino-clicker">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game12} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Disc Destroy Game</h1>
                  <p>“Disc Destroy” is a hyper-casual game where you have to destroy all the disks of your opponent, which can be a Bot or your friend sitting next to you. As soon as you are ready, release to make a throw!</p>

                  <Link className='Play_Btn' to="/disc-destroy">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game13} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Drift Bus Game</h1>
                  <p>Drift Bus is a challenging drifting game where players master the art of precision drifting through challenging tracks. With intuitive controls, players tap and hold to navigate sharp turns.</p>

                  <Link className='Play_Btn' to="/drift-bus">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game14} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Find Diffrence Game</h1>
                  <p>Embark on an immersive visual challenge with “Find The 3 Difference” – a captivating puzzle game that puts your attention to detail to the test! Explore intricate images filled with subtle variations. </p>

                  <Link className='Play_Btn' to="/find-diffrence">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game15} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Flying Jet Game</h1>
                  <p>Suitable for your design needs, This template are 100% vector work, everything is editable, easy to resize. font information is on *help, just download the font and you can change to be any name you want.</p>

                  <Link className='Play_Btn' to="/flying-jet">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game16} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Grass Cutting Game</h1>
                  <p>This captivating illustration showcases a young gardener diligently cutting grass with clippers, meticulously shaping the green expanse into a beautiful and well-maintained landscape. </p>

                  <Link className='Play_Btn' to="/grass-cutting">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game17} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Hat Hands Game</h1>
                  <p>Merry Christmas!! This is santa hat illustration set in vector. These santa hat illustration use cartoon style and each item have different style. There are 12 different hat.</p>

                  <Link className='Play_Btn' to="/hat-hands">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game18} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>

                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Night Ninja Game</h1>
                  <p>Ninja Suitable for personal or Mascot Logo, All elements are editable with adobe illustrator! Editable Text, Before you open the Logo Files, you must install the font first in the Main File Included</p>
                  {/* 
                  <p>Play <b>Night Ninja</b> Game Ane Connect All The Night And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p> */}

                  <Link className='Play_Btn' to="/night-ninja">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game19} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Panda Bloock Game</h1>
                  <p>Cute Chinese Panda Kungfu Master Mascot Cartoon. All elements can be edited in adobe illustrator. Fonts are not include, you can get links file in the document folder or in the readme file</p>

                  <Link className='Play_Btn' to="/panda-bloock">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game20} alt="Game Icon Not Load" />
              </div>
              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Pirates Game</h1>
                  <p>
                    Play <b>Pirates</b> Game Ane Connect All The Pirates And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.
                  </p>
                  <Link className='Play_Btn' to="/pirates">Play Now</Link>
                </div>
              </div>
            </div>
            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game21} alt="Game Icon Not Load" />
              </div>


              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Preposition Quest Game</h1>
                  <p>Play <b>Preposition Quest</b> Game Ane Connect All The Preposition And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/preposition-quest">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game22} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>RLeangue Game</h1>
                  <p>Play <b>RLeangue</b> Game Ane Connect All The RLeangue And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/rleangue">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game23} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Rope Challenge Game</h1>
                  <p>Play <b>Rope Challenge</b> Game Ane Connect All The Rope And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/rope-challenge">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game24} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Snowland Game</h1>
                  <p>Play <b>Snowland</b> Game Ane Connect All The Snowland And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/snowland">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game25} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Summer Maze Game</h1>
                  <p>Play <b>Summer Maze</b> Game Ane Connect All The Summer And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/summer-maze">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game26} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Super Bouncy Egg Game</h1>
                  <p>Play <b>Super Bouncy Egg</b> Game Ane Connect All The Super And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/super-bouncy-egg">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game27} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>The Viking Game</h1>
                  <p>Play <b>The Viking</b> Game Ane Connect All The Viking And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/the-viking">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game28} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Tower Of Fall Game</h1>
                  <p>Play <b>Tower Of Fall</b> Game Ane Connect All The Tower And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/tower-if-fall">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game29} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Two Cat Cute Game</h1>
                  <p>Play <b>Two Cat Cute</b> Game Ane Connect All The Two And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/two-cat-cute">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game30} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h1 style={{ color: "#8C52FE", fontWeight: "bold" }}>Valentine Hidden Game</h1>
                  <p>Play <b>Valentine Hidden</b> Game Ane Connect All The Valentine And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/valentine-hidden">Play Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className='Blog_Seo'>
      # Justify Games Play Free Games In justifygames.fun Like poki.com, crazygames.com And More
      </h1>
    </>
  )
}

export default Blog