import React from 'react'
import GameBox from '../Components/GameBox'
import Img from '../Assets/Logo-Icon.png'
import Slider from '../Components/Slider'

function Home() {
  return (
    <>
    <Slider />
      <div className="PlayGame_Box">
      <h2 class="Hover_Image_Name">
        <img src={Img} alt="https://www.justifygames.fun/" /><h1> Games</h1></h2>
        <GameBox />
      </div>
    </>
  )
}

export default Home